import React from "react";
import Card from "react-bootstrap/Card";
import { AiOutlineArrowRight } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0" data-aos="zoom-in">
          <p className="card-body" style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="primary-header">Neetika Aryal</span> from Putalibazar, Syangja, Nepal. I am an Electronics, Communication and Information Engineer and a passionate programmer.
            <br />
            <br />
            In addition to coding, here are some other things I enjoy doing!
          </p>
          <ul>
            <li className="about-activity">
              <AiOutlineArrowRight /> Learning New Technologies
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Serving People
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Playing Badminton
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Traveling
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
