import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";
import leo from "../../Assets/Projects/leo.png";
import traffic from "../../Assets/Projects/traffic.png";
import drowsy from "../../Assets/Projects/drowsy.png";
import epc from "../../Assets/Projects/epc.png";
import miniplugins from "../../Assets/Projects/miniplugins.png";



import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="Fluorescent-Blue">Works </strong>
        </h1>
        <p>Here are a few projects I've worked on recently.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12} lg={6} className="project-card">
            <ProjectCard
              imgPath={traffic}
              title="Traffic Anomaly Detection and Alert System"
              description="Developed to improve road safety and traffic regulation, this real-time traffic monitoring system utilizes YOLOv3 and Raspberry Pi to detect overspeeding, helmet violations, 
              and vehicle tags. By enabling automated enforcement and monitoring, it helps reduce traffic violations, enhance law enforcement efficiency, and promote safer driving behavior."
              skills="Computer Vision, IoT Integration, Real-time Data Processing"
              contribution="Designed and implemented the AI model, configured hardware integration, and conducted feasibility analysis for urban road networks."
              paperLink="https://www.ijsred.com/volume6/issue5/IJSRED-V6I5P126.pdf" // Replace with your research paper link
            />
          </Col>
          <Col md={12} lg={6} className="project-card">
            <ProjectCard
              imgPath={drowsy}
              title="Driver Drowsiness and Distraction Monitoring System"
              description="Designed to reduce road accidents caused by driver fatigue and distraction, 
              this intelligent driver safety system uses VGG16 and Raspberry Pi to detect signs of drowsiness and inattention. Integrated with physiological sensors, it monitors real-time indicators such as eye closure and heart rate, providing instant alerts to help drivers stay alert and prevent potential crashes, enhancing overall road safety."
              skills="Deep Learning, IoT and Sensor Integration, Data Augmentation"
              contribution="Developed the drowsiness detection model, integrated hardware components, and published a research paper."
              paperLink="https://www.ijsred.com/volume6/issue5/IJSRED-V6I5P123.pdf" // Replace with your research paper link
            />
          </Col> 

          <Col md={12} lg={6} className="project-card">
            <ProjectCard
              imgPath={leo}
              title="Executive Secretary - Leo Club of Kathmandu Valley Youth"
              description="Leo Club of Kathmandu Valley Youth, a local chapter of Leo Club International, a youth-led wing of Lions Club International, focuses on leadership, experience, and opportunity through service. As Executive Secretary, I led a team of 40 members to organize cultural events, environmental initiatives, and community support programs for orphanages and old age homes"
              skills="Leadership, Event Coordination, Stakeholder Collaboration, International Collaboration, Community Outreach, Humanitarian Assistance"
              contribution="Spearheaded tree planting and cleanup drives, coordinated visits to orphanages, and fostered international relationships to promote societal betterment."
              websiteLink="https://www.lionsclubs.org/en/discover-our-clubs/about-leos" // Replace with a relevant link
            />
          </Col>

          <Col md={12} lg={6} className="project-card">
            <ProjectCard
              imgPath={miniplugins}
              title="Co-founder / Product Developer - Mini Plugins"
              description="Mini Plugins is a platform dedicated to creating lightweight, high-performance WordPress plugins that enhance 
              website functionality without adding unnecessary bloat. The company focuses on delivering simple, easy-to-use tools that 
              improve the WordPress experience for users, particularly in areas like e-commerce, user management, and security. 
              Our plugins are designed to be minimalistic yet powerful, ensuring they meet the highest standards of performance and reliability"
              skills="Product Development, Plugin Development, WordPress Optimization, Performance Engineering, Security Implementation, E-commerce Integration, Team Leadership, Innovation Management"
              contribution=" I played a key role in designing and developing high-performance WordPress plugins focused on e-commerce, user management, and security. 
              I analyze user needs, generate product ideas, and work closely with my team to develop lightweight, efficient, and user-friendly plugins that enhance website functionality and improve the overall WordPress experience."
              websiteLink="https://miniplugins.com/" // Replace with a relevant link
            />
          </Col>

          <Col md={12} lg={6} className="project-card">
            <ProjectCard
              imgPath={epc}
              title="Vice President - Electronics Project Club (EPC)"
              description="Since its establishment in 2014, this club has been serving its members and other various interested students in the field of technology.
               Having more than 100 members this club aims to quench the thirst of its member and as well as provide a platform to realize their potential in the field of innovative designs and ideas. All the year around we organize various programs that help the students. "
              skills="Leadership, Event Coordination, Technical Training"
              contribution="Organized hands-on workshops on robotics, software development, and machine learning, collaborated with local schools and communities, and promoted STEM education through interactive projects and events."
            />
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;